import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { ButtonLink } from "../atoms";
import Img from "gatsby-image";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const SponsorsScroller = () => {
  const sponsors = useStaticQuery(graphql`
    query SponsorsScrollerTwoQuery {
      allFile(filter: { relativeDirectory: { eq: "sponsors" } }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                url
                logo {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allFile.edges;

  return (
    <section className={"sm:px-4 py-16 section bg-white"}>
      <div className={"container mx-auto"}>
        <h2 className={"h1 text-center"}>
          I{" "}
          <span role={"img"} aria-label={"heart-emoji"}>
            ❤️
          </span>{" "}
          BD Sponsors
        </h2>
        <CarouselProvider
          totalSlides={sponsors.length}
          naturalSlideHeight={100}
          naturalSlideWidth={200}
          visibleSlides={2}
          infinite
          isPlaying
          interval={2000}
          className={"max-w-4xl mx-auto mt-8"}
        >
          <Slider>
            {sponsors.map(({ node }, i) => (
              <Slide
                key={node.id}
                index={i}
                innerClassName={"flex flex-col justify-center"}
              >
                <SponsorCard
                  title={node.childMarkdownRemark.frontmatter.title}
                  logo={node.childMarkdownRemark.frontmatter.logo}
                  url={node.childMarkdownRemark.frontmatter.url}
                />
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
        <div className={"flex justify-center mt-16"}>
          <ButtonLink url={"/sponsors"} className={"mx-auto"}>
            View all sponsors
          </ButtonLink>
        </div>
      </div>
    </section>
  );
};

const SponsorCard = ({ title, url, logo }) => {
  return (
    <Img
      // className="w-20 h-20 object-cover rounded-full border-2 border-gray-100 bg-white"
      // className={"mx-auto max-w-md"}
      // css={css`
      //   max-height: 8rem;
      //   pointer-events: none;
      //   @media (min-width: 720px) {
      //     max-width: 90%;
      //   }
      // `}
      imgStyle={{ objectFit: "contain" }}
      fluid={logo.childImageSharp.fluid}
      alt={title}
    />
  );
};

export default SponsorsScroller;
