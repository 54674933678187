import * as React from "react";
import { ButtonLink } from "../atoms";

import GallerySlider from "../molecules/GallerySlider";

const Hero = () => {
  return (
    <section className={"flex flex-wrap pt-16"}>
      <div
        className={`w-full md:w-1/2 p-2 md:p-6 flex flex-col justify-center text-center md:text-right space-y-5`}
      >
        <div className={"max-w-lg mx-auto md:ml-auto md:mr-0 space-y-2"}>
          <h1 className={"h2 md:text-2xl lg:text-4xl uppercase font-extrabold"}>
            We want to share all the reasons we
            <span role={"img"} aria-label={"heart-emoji"}>
              {" "}
              ❤️️{" "}
            </span>
            BD
          </h1>
          <p className={"text-sm"}>
            Community members and organizations are sharing all of the reasons
            they love Beaver Dam via photos, stories, and testimonials.
            <br />
            Tell us why you love BD!
          </p>
        </div>
        <div className={"flex justify-center md:justify-end space-x-2"}>
          <ButtonLink url={"/#share"}>Submit your reason</ButtonLink>
          <ButtonLink alt url={"/contact"}>
            Sponsorship
          </ButtonLink>
        </div>
      </div>
      <div className={"w-full md:w-1/2 mt-8 md:mt-0 max-w-2xl mx-auto"}>
        <GallerySlider />
      </div>
    </section>
  );
};

export default Hero;
