import * as React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import Hero from "../components/sections/Hero";
import Testimonials from "../components/sections/Testimonials";
import SponsorsScroller from "../components/sections/SponsorsScroller";
import Share from "../components/sections/Share";
import BecomeASponsor from "../components/sections/BecomeASponsor";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title={"Home"} />
      <Hero />
      <Testimonials />
      <SponsorsScroller />
      <Share />
      <BecomeASponsor />
      <div className={"pb-16"} />
    </Layout>
  );
};

export default IndexPage;
